@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&display=swap');

#main-logo {
  height: 32px !important;
}

.main-content {
  display: flex;
}

.background-image {
  width: 1440px;
  height: auto;
  max-width: 100%;
  position: absolute;
  z-index: -1;
}

.first-area {
  width: 1440px;
  display: flex;
}

.lane-40vh {
  width: 40%;
  max-width: 576px;
}

.lane-50vh {
  width: 50%;
  max-width: 720px;
}

.lane-60vh {
  width: 60%;
  max-width: 864px;
}

.lane-70vh {
  width: 70%;
  max-width: 1008px;
}

.first-area .left-lane {
  justify-content: center;
}

.first-area .right-lane {
  margin-top: calc(5%);
  margin-right: calc(3%);
}

.first-area .text-area {
  position: relative;
  top: calc(-6%);
}

.teaser-pic {
  width: 100%;
  height: auto;
  max-width: 864px;
}

.main-container {
  max-width: 1440px;
  margin: 0 auto;
  box-shadow: 0 16px 48px #e3e7eb;
}

.header-links {
  justify-content: flex-end;
  display: flex;
}

.header-links a {
  margin: 5px;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 700;
  color: #3b3d3fff;
  text-decoration: none;
  line-height: 16px;
  padding: 8px 12px;
  background-color: #ededed;
  font-family: Montserrat;
}

.footer {
  height: auto;
  width: 50%;
  max-width: 720px;
  margin-top: calc(10%);
}

.footer-section {
  width: 100%;
  max-width: 1440px;
  margin-top: calc(3%);
  background: linear-gradient(0deg,rgba(119,119,119,1) 0%,rgba(255,255,255,1) 100%);
  display: flex;
  color: #3b3d3fff;
}

.footer-section .footer-lane {
  margin-left: calc(10%);
  padding-top: calc(3%);
  width: 15%;
}

.footer-section .footer-header {
  font-weight: bold;
  font-size: 1.2rem;
}

.footer-section a {
  text-decoration: none;
  display: block;
  margin-bottom: calc(10%);
  color: #3b3d3fff;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.color-white {
  color: white;
}

.color-dark {
  color: #3b3d3fff;
}

.text-center {
  display: flex;
  justify-content: center;
}

.text {
  font-family: Montserrat;
}

.text-light {
  font-weight: lighter;
}

.text-medium {
  font-weight: 500;
}

.text-bold {
  font-weight: bold;
}

.text-thin {
  font-weight: 100;
}

.logo-pinc {
  font-weight: 100;
  color: #af1455ff;
  font-size: 4.5rem;
}

.logo-dot {
  font-weight: 100;
  color: #3b3d3fff;
  font-size: 4.5rem;
}

.logo-business {
  font-weight: bold;
  color: #3b3d3fff;
  font-size: 4.5rem;
}

.logo-image {
  width: 155px;
}

.logo-subtitle {
  font-weight: 300;
  color: #3b3d3fff;
  font-size: 2rem;
  text-align: center;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.first-area .header-title {
  margin-top: 170px;
}

.header-title {
  font-weight: bold;
  color: #3b3d3fff;
  font-size: 2.9rem;
  margin-top: 100px;
  margin-left: calc(5%);
}

.second-area-text {
  margin-top: calc(5%);
  margin-left: calc(5%);
  font-size: 1.3rem;
  line-height: 1.6;
}

.second-area-text .line {
  padding-bottom: 125px;
}

.second-area-pic {
  width: 100%;
  height: auto;
  max-width: 720px;
  margin-top: calc(5%);
}

.third-area-pic {
  height: auto;
  width: 520px;
  margin-top: calc(5%);
  margin-left: calc(7%);
  margin-right: calc(7%);
}

.third-area-text {
  margin-top: calc(10%);
  font-size: 1.3rem;
}

.third-area-subtext {
  margin-top: calc(10%);
  line-height: 1.6;
  margin-left: 105px;
  margin-right: 105px;
}

.third-area-subtext b {
  font-weight: bold;
}

.forth-area-text {
  margin-left: calc(5%);
  margin-top: calc(10%);
  font-size: 1.3rem;
}

.forth-area-pic {
  height: auto;
  width: 272px;
  margin-top: calc(5%);
  margin-left: calc(7%);
  margin-right: calc(7%);
}

.forth-area-subtext {
  margin-top: calc(10%);
  line-height: 1.6;
  margin-left: 55px;
  margin-right: 55px;
}

.more-space {
  margin-top: calc(15%);
}

.action-btn {
  padding: 14px 24px;
  border: 0 none;
  /*font-weight: bold;*/
  background: #e11e72;
  color: white;
  font-size: 2rem;
  margin-top: calc(2%);
  margin-right: 15px;
  border-radius: 0.5rem;
  text-decoration: none;
}

.first-area .action-btn {
  margin-right: 0;
  font-size: 2.4rem;
}

.second-area-text .action-btn {
  margin-top: calc(10%);
}

.third-area-subtext .action-btn {
  margin-top: calc(10%);
  font-size: 3rem;
}

/* 5. section */
.fifth-section .header-title {
  margin-top: calc(5%);
}

.fifth-section .lane-50vh {
  margin-top: calc(2%);
}

.fifth-section .description-box {
  margin-top: calc(5%);
  display: flex;
}
.fifth-section .description-text-box {
  margin-left: calc(5%);
  margin-right: calc(5%);
}
.fifth-section .header {
  font-size: 2.5rem;
  font-weight: bold;
}
.fifth-section .description {
  margin-top: calc(1%);
  font-size: 1.3rem;
  font-weight: 300;
}
.fifth-section .icon {
  margin-left: calc(10%);
  width: calc(10%);
  height: calc(5%)
}

.fifth-section .sub-title
{
  font-weight: bold;
  color: white;
  font-size: 2.9rem;
  margin-top: calc(7%);
  margin-left: calc(5%);
}

.fifth-section .sub-title a{
  color: white;
}

@media (max-width: 1440px) {
  .first-area .action-btn {
    font-size: 2.6vw;
    padding: 1vw 2vw;
  }

  .second-area .action-btn {
    font-size: 2.2vw;
    margin-right: calc(2%);
    padding: 1vw 2vw;
  }

  .third-area .action-btn {
    font-size: 3.2vw;
    margin-right: calc(2%);
    padding: 1vw 2vw;
  }

  .text-alle-assets-inhalt {
    font-size: 2.8vw;
  }
  .background-image {
    margin-top: calc(10%);
  }

  .logo-pinc {
    font-size: 5vw;
  }
  .logo-dot {
    font-size: 5vw;
  }
  .logo-business {
    font-size: 5vw;
  }
  .logo-subtitle {
    font-size: 2vw;
  }
  .logo-image {
    width: 11vw;
  }
  .first-area .header-title {
    margin-top: calc(12%);
    font-size: 3.2vw;
  }
  .header-title {
    margin-top: calc(12%);
    font-size: 3.2vw;
  }
  .second-area-text {
    margin-top: calc(5%);
    margin-left: calc(5%);
    font-size: 1.4vw;
  }

  .second-area-pic {
    width: 50vw;
  }

  .third-area-pic {
    height: auto;
    width: 37vw;
    margin-top: calc(5%);
    margin-left: calc(7%);
  }

  .third-area-text {
    font-size: 1.4vw;
  }

  .forth-area-text {
    font-size: 1.4vw;
  }

  .forth-area-pic {
    height: auto;
    width: 19vw;
    margin-top: calc(5%);
    margin-left: calc(7%);
  }

  .third-area-subtext {
    margin-top: calc(10%);
    line-height: 1.6;
    margin-left: calc(15%);
    margin-right: calc(15%);
  }

  .forth-area-subtext {
    font-size: 1.5vw;
    margin-left: calc(5%);
    margin-right: calc(2%);
  }

  .fifth-section .header {
    font-size: 2.7vw;
  }
  .fifth-section .description {
    font-size: 1.4vw;
  }
  .fifth-section .sub-title
  {
    font-size: 2.7vw;
  }
  .footer-section .footer-header {
    font-size: 1.5vw;
  }
  .footer-section .footer-contact {
    font-size: 1.3vw;
  }
}

@media (min-width: 1441px) {
  .text-alle-assets-inhalt {
    font-size: 2.5rem;
  }

  .background-image {
    margin-top: 143px;
  }
}